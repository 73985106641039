var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PatientInfoModal", {
        attrs: {
          show: _vm.$route.name === "intakehistory" ? true : _vm.showModal,
        },
        on: { close: _vm.onCloseModal },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ openNotesHandler }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "tw-px-4 md:tw-px-12 tw-w-full verified-user",
                  },
                  [
                    (
                      _vm.$route.name === "intakehistory"
                        ? !_vm.patientInfoGetter.questions
                        : !_vm.patientInfo.questions
                    )
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "tw-flex tw-absolute tw-justify-center tw-items-center tw-inset-0",
                          },
                          [
                            _c("span", { staticClass: "tw-text-2xl" }, [
                              _vm._v("Loading..."),
                            ]),
                          ]
                        )
                      : [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-flex tw-flex-col tw-flex-grow tw-h-screen",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "intake-history-header header-nav tw-relative md:tw-sticky tw-top-0 tw-py-5 tw-flex tw-justify-between tw-items-center tw-flex-none",
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "tw-flex-initial tw-flex tw-items-center tw-text-warning",
                                      },
                                      [
                                        _vm.$route.name === "intakehistory"
                                          ? _c("span", {
                                              staticClass:
                                                "back-arrow tw-mr-3 tw-cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$router.back()
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.previousRoute
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "tw-text-warning",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.previousRoute.meta
                                                        .title
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "tw-pr-6 tw-hidden md:tw-block",
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase px-5",
                                          attrs: {
                                            pill: "",
                                            size: "sm",
                                            variant: "outline-primary",
                                          },
                                          on: { click: openNotesHandler },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.currentDoctor.id
                                                  ? "Notes"
                                                  : "Add Notes"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "tw-flex-grow" },
                                [
                                  _vm.notesList.length > 0
                                    ? _c("div", { staticClass: "mb-5" }, [
                                        _c(
                                          "h4",
                                          {
                                            staticClass:
                                              "tw-mb-6 tw-text-xl tw-text-primary",
                                          },
                                          [_vm._v("Notes")]
                                        ),
                                        _c(
                                          "ul",
                                          {
                                            staticClass:
                                              "list-unstyled tw-gap-10",
                                          },
                                          _vm._l(
                                            _vm.notesList,
                                            function (noteInfo) {
                                              return _c(
                                                "li",
                                                {
                                                  key: noteInfo.id,
                                                  staticClass:
                                                    "tw-row-span-1 tw-overflow-hidden pb-3 mb-3",
                                                  staticStyle: {
                                                    "border-bottom":
                                                      "1px solid #f2f2f2",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    { staticClass: "mb-1" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(noteInfo.notes)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "tw-text-muted",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.parseDate(
                                                              noteInfo.timestampcreated,
                                                              "MM/dd/yyyy hh:mmaaa"
                                                            )
                                                          ) +
                                                          " by Dr. " +
                                                          _vm._s(
                                                            noteInfo.doctor
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.$route.name === "intakehistory"
                                      ? _vm.patientInfoGetter.followupquestions
                                      : _vm.patientInfo.followupquestions,
                                    function (followups, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "mb-5" },
                                        [
                                          _c(
                                            "h4",
                                            {
                                              staticClass:
                                                "tw-mb-6 tw-text-xl tw-text-muted",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    followups.type === "1YEAR"
                                                      ? "1YR"
                                                      : "10WK"
                                                  ) +
                                                  " Follow-Up Questions | " +
                                                  _vm._s(
                                                    _vm.parseDate(
                                                      followups.timestampanswered,
                                                      "MM/dd/yyyy hh:mmaaa"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "health-question-wrapper",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "health-question",
                                                },
                                                _vm._l(
                                                  followups.followupanswer,
                                                  function (
                                                    questionitems,
                                                    indexquestions
                                                  ) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: indexquestions,
                                                        staticClass: "hq-list",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "tw-pb-6",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "intake-label",
                                                                attrs: {
                                                                  for: `followups${indexquestions}`,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    indexquestions +
                                                                      1
                                                                  ) +
                                                                    ". " +
                                                                    _vm._s(
                                                                      questionitems.question
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "pl-0 intake-input",
                                                                style: `color:${questionitems.highlightcolor};  border-bottom: 1px solid #D2DDEC;`,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      questionitems.answer.replace(
                                                                        /(\r\n|\n|\r)/gm,
                                                                        "\r\n"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm.unreadMessageCounter
                                    ? _c(
                                        "div",
                                        { staticClass: "tw-py-5" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "tw-px-0 tw-underline",
                                              attrs: { variant: "link" },
                                              on: {
                                                click: _vm.onMessageRedirect,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.unreadMessageCounter
                                                  ) +
                                                  " unread " +
                                                  _vm._s(
                                                    _vm.pluralize(
                                                      _vm.unreadMessageCounter,
                                                      "message"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "h4",
                                    {
                                      staticClass:
                                        "tw-mb-6 tw-text-xl tw-text-muted",
                                    },
                                    [_vm._v(" Health Questions ")]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "health-question-wrapper" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "health-question" },
                                        _vm._l(
                                          _vm.$route.name === "intakehistory"
                                            ? _vm.patientInfoGetter.questions
                                            : _vm.patientInfo.questions,
                                          function (healthQuestion, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "hq-list",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "tw-pb-6" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "intake-label",
                                                        attrs: {
                                                          for: `healthQuestion${index}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(index + 1) +
                                                            ". " +
                                                            _vm._s(
                                                              healthQuestion.question
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    healthQuestion.question !=
                                                    "Stated allergies"
                                                      ? _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "pl-0 intake-input",
                                                            style: `color:${healthQuestion.highlightcolor}; border-bottom: 1px solid #D2DDEC;`,
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  healthQuestion.answer
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "pl-0 intake-input",
                                                            style: `color:${healthQuestion.highlightcolor}`,
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  healthQuestion.answer.replace(
                                                                    /(\r\n|\n|\r)/gm,
                                                                    "\r\n"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "tw-p-5 tw-flex-none tw-sticky tw-bottom-0 action-btn intake-history-footer",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "tw-text-right tw-space-x-3",
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "text-primary tw-underline",
                                          attrs: {
                                            pill: "",
                                            size: "lg",
                                            variant: "link",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$router.push(
                                                `/patients/${
                                                  _vm.$route.name ===
                                                  "intakehistory"
                                                    ? _vm.patientInfoGetter.id
                                                    : _vm.patientInfo.id
                                                }/treatments`
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" View Prescriptions ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            pill: "",
                                            size: "lg",
                                            variant: "outline-primary",
                                          },
                                          on: { click: _vm.onMessageRedirect },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "tw-flex tw-items-center",
                                            },
                                            [
                                              _c("span", [_vm._v("message")]),
                                              _vm.unreadMessageCounter
                                                ? _c(
                                                    "b-badge",
                                                    {
                                                      staticClass:
                                                        "ml-2 text-white",
                                                      attrs: {
                                                        pill: "",
                                                        variant: "secondary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.unreadMessageCounter
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      !_vm.currentDoctor.id &&
                                      !(
                                        (_vm.patientInfoGetter &&
                                          _vm.patientInfoGetter.isbanned) ||
                                        (_vm.patientInfo &&
                                          _vm.patientInfo.isbanned)
                                      )
                                        ? _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                size: "lg",
                                                pill: "",
                                                variant: "primary",
                                              },
                                              on: { click: _vm.onPrescribe },
                                            },
                                            [_vm._v(" prescribe ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                  ],
                  2
                ),
                _c(
                  "b-modal",
                  {
                    attrs: {
                      id: "unread-message",
                      "hide-header": "",
                      centered: "",
                      "hide-footer": "",
                      size: "md",
                    },
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", { attrs: { cols: "12", sm: "4" } }, [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "30",
                                height: "27",
                                viewBox: "0 0 30 27",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z",
                                  fill: "#1A6A72",
                                },
                              }),
                            ]
                          ),
                        ]),
                        _c(
                          "b-col",
                          {
                            staticClass: "py-6",
                            attrs: { cols: "12", sm: "8" },
                          },
                          [
                            _c(
                              "h1",
                              {
                                staticClass: "heading mb-4",
                                staticStyle: { "line-height": "30px" },
                              },
                              [
                                _vm._v(" You have "),
                                _c("span", { staticClass: "text-secondary" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.unreadMessageCounter) +
                                      " unread " +
                                      _vm._s(
                                        _vm.pluralize(
                                          _vm.unreadMessageCounter,
                                          "message"
                                        )
                                      )
                                  ),
                                ]),
                                _vm._v(
                                  ", do you want to proceed to prescribe? "
                                ),
                              ]
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mt-4 mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", sm: "7" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          block: "",
                                          variant: "danger",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push(
                                              `/prescriptions/order/${
                                                _vm.$route.name ===
                                                "intakehistory"
                                                  ? _vm.patientInfoGetter.id
                                                  : _vm.patientInfo.id
                                              }`
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Confirm ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mb-3" },
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12", sm: "7" } },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          block: "",
                                          variant: "outline-primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push(
                                              `/patients/${
                                                _vm.$route.name ===
                                                "intakehistory"
                                                  ? _vm.patientInfoGetter.id
                                                  : _vm.patientInfo.id
                                              }/message`
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Reply ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }