<template>
  <div>
    <PatientInfoModal
      :show="$route.name === 'intakehistory' ? true : showModal"
      @close="onCloseModal"
    >
      <template slot-scope="{ openNotesHandler }">
        <div class="tw-px-4 md:tw-px-12 tw-w-full verified-user">
          <div
            v-if="
              $route.name === 'intakehistory'
                ? !patientInfoGetter.questions
                : !patientInfo.questions
            "
            class="
              tw-flex tw-absolute tw-justify-center tw-items-center tw-inset-0
            "
          >
            <span class="tw-text-2xl">Loading...</span>
          </div>
          <template v-else>
            <div class="tw-flex tw-flex-col tw-flex-grow tw-h-screen">
              <div
                class="
                  intake-history-header
                  header-nav
                  tw-relative
                  md:tw-sticky
                  tw-top-0
                  tw-py-5
                  tw-flex
                  tw-justify-between
                  tw-items-center
                  tw-flex-none
                "
              >
                <div>
                  <span
                    class="
                      tw-flex-initial tw-flex tw-items-center tw-text-warning
                    "
                  >
                    <span
                      v-if="$route.name === 'intakehistory'"
                      class="back-arrow tw-mr-3 tw-cursor-pointer"
                      @click="$router.back()"
                    ></span>
                    <span class="tw-text-warning" v-if="previousRoute">
                      {{ previousRoute.meta.title }}
                    </span>
                  </span>
                </div>
                <div class="tw-pr-6 tw-hidden md:tw-block">
                  <b-button
                    pill
                    size="sm"
                    variant="outline-primary"
                    class="text-uppercase px-5"
                    @click="openNotesHandler"
                  >
                    {{ currentDoctor.id ? "Notes" : "Add Notes" }}
                  </b-button>
                </div>
              </div>
              <div class="tw-flex-grow">
                <div v-if="notesList.length > 0" class="mb-5">
                  <h4 class="tw-mb-6 tw-text-xl tw-text-primary">Notes</h4>
                  <ul class="list-unstyled tw-gap-10">
                    <li
                      class="tw-row-span-1 tw-overflow-hidden pb-3 mb-3"
                      style="border-bottom: 1px solid #f2f2f2"
                      v-for="noteInfo in notesList"
                      :key="noteInfo.id"
                    >
                      <p class="mb-1">{{ noteInfo.notes }}</p>
                      <small class="tw-text-muted">
                        {{
                          parseDate(
                            noteInfo.timestampcreated,
                            "MM/dd/yyyy hh:mmaaa"
                          )
                        }}
                        by Dr.
                        {{ noteInfo.doctor }}
                      </small>
                    </li>
                  </ul>
                </div>

                <div
                  v-for="(followups, index) in $route.name === 'intakehistory'
                    ? patientInfoGetter.followupquestions
                    : patientInfo.followupquestions"
                  :key="index"
                  class="mb-5"
                >
                  <h4 class="tw-mb-6 tw-text-xl tw-text-muted">
                    {{ followups.type === "1YEAR" ? "1YR" : "10WK" }} Follow-Up
                    Questions |
                    {{
                      parseDate(
                        followups.timestampanswered,
                        "MM/dd/yyyy hh:mmaaa"
                      )
                    }}
                  </h4>

                  <div class="health-question-wrapper">
                    <div class="health-question">
                      <div
                        class="hq-list"
                        v-for="(
                          questionitems, indexquestions
                        ) in followups.followupanswer"
                        :key="indexquestions"
                      >
                        <div class="tw-pb-6">
                          <label
                            class="intake-label"
                            :for="`followups${indexquestions}`"
                            >{{ indexquestions + 1 }}.
                            {{ questionitems.question }}
                          </label>

                          <p
                            class="pl-0 intake-input"
                            :style="`color:${questionitems.highlightcolor};  border-bottom: 1px solid #D2DDEC;`"
                          >
                            {{
                              questionitems.answer.replace(
                                /(\r\n|\n|\r)/gm,
                                "\r\n"
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <ul
                    class="
                      list-unstyled
                      tw-grid tw-grid-cols-1
                      md:tw-grid-cols-2
                      tw-gap-10
                    "
                  >
                    <li
                      class="tw-row-span-1 tw-overflow-hidden"
                      v-for="(
                        questionitems, indexquestions
                      ) in followups.followupanswer"
                      :key="indexquestions"
                    >
                      <label
                        class="intake-label"
                        :for="`followups${indexquestions}`"
                        >{{ indexquestions + 1 }}. {{ questionitems.question }}
                      </label>
                      <b-form-textarea
                        disabled
                        max-rows="15"
                        class="pl-0 intake-input"
                        type="text"
                        style="overflow: hidden"
                        :value="
                          questionitems.answer.replace(/(\r\n|\n|\r)/gm, '\r\n')
                        "
                        :style="`color:${questionitems.highlightcolor}`"
                      ></b-form-textarea>
                    </li>
                  </ul> -->
                </div>

                <div v-if="unreadMessageCounter" class="tw-py-5">
                  <b-button
                    variant="link"
                    class="tw-px-0 tw-underline"
                    @click="onMessageRedirect"
                  >
                    {{ unreadMessageCounter }} unread
                    {{ pluralize(unreadMessageCounter, "message") }}
                  </b-button>
                </div>

                <h4 class="tw-mb-6 tw-text-xl tw-text-muted">
                  Health Questions
                </h4>

                <div class="health-question-wrapper">
                  <div class="health-question">
                    <div
                      class="hq-list"
                      v-for="(healthQuestion, index) in $route.name ===
                      'intakehistory'
                        ? patientInfoGetter.questions
                        : patientInfo.questions"
                      :key="index"
                    >
                      <div class="tw-pb-6">
                        <label
                          class="intake-label"
                          :for="`healthQuestion${index}`"
                          >{{ index + 1 }}. {{ healthQuestion.question }}
                        </label>
                        <p
                          v-if="healthQuestion.question != 'Stated allergies'"
                          class="pl-0 intake-input"
                          :style="`color:${healthQuestion.highlightcolor}; border-bottom: 1px solid #D2DDEC;`"
                        >
                          {{ healthQuestion.answer }}
                        </p>

                        <p
                          v-else
                          class="pl-0 intake-input"
                          :style="`color:${healthQuestion.highlightcolor}`"
                        >
                          {{
                            healthQuestion.answer.replace(
                              /(\r\n|\n|\r)/gm,
                              "\r\n"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="
                  tw-p-5 tw-flex-none tw-sticky tw-bottom-0
                  action-btn
                  intake-history-footer
                "
              >
                <div class="tw-text-right tw-space-x-3">
                  <b-button
                    pill
                    size="lg"
                    variant="link"
                    class="text-primary tw-underline"
                    @click="
                      $router.push(
                        `/patients/${
                          $route.name === 'intakehistory'
                            ? patientInfoGetter.id
                            : patientInfo.id
                        }/treatments`
                      )
                    "
                  >
                    View Prescriptions
                  </b-button>
                  <b-button
                    pill
                    size="lg"
                    variant="outline-primary"
                    @click="onMessageRedirect"
                  >
                    <span class="tw-flex tw-items-center">
                      <span>message</span>
                      <b-badge
                        pill
                        variant="secondary"
                        v-if="unreadMessageCounter"
                        class="ml-2 text-white"
                      >
                        {{ unreadMessageCounter }}
                      </b-badge>
                    </span>
                  </b-button>
                  <b-button
                    size="lg"
                    pill
                    variant="primary"
                    @click="onPrescribe"
                    v-if="
                      !currentDoctor.id &&
                      !(
                        (patientInfoGetter && patientInfoGetter.isbanned) ||
                        (patientInfo && patientInfo.isbanned)
                      )
                    "
                  >
                    prescribe
                  </b-button>
                </div>
              </div>
            </div>
          </template>
        </div>

        <b-modal id="unread-message" hide-header centered hide-footer size="md">
          <b-row>
            <b-col cols="12" sm="4">
              <svg
                width="30"
                height="27"
                viewBox="0 0 30 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.4395 18.5298L15.6275 6.36712H14.3725L3.5585 18.532L3.5365 0H0V27H1.3615L15.0005 10.8077L28.5975 26.8985L28.683 27H30V0H26.4175L26.4395 18.5298Z"
                  fill="#1A6A72"
                />
              </svg>
            </b-col>
            <b-col cols="12" sm="8" class="py-6">
              <h1 class="heading mb-4" style="line-height: 30px">
                You have
                <span class="text-secondary">
                  {{ unreadMessageCounter }} unread
                  {{ pluralize(unreadMessageCounter, "message") }}</span
                >, do you want to proceed to prescribe?
              </h1>
              <b-row class="mt-4 mb-3">
                <b-col cols="12" sm="7">
                  <b-button
                    pill
                    block
                    variant="danger"
                    @click="
                      $router.push(
                        `/prescriptions/order/${
                          $route.name === 'intakehistory'
                            ? patientInfoGetter.id
                            : patientInfo.id
                        }`
                      )
                    "
                  >
                    Confirm
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mb-3">
                <b-col cols="12" sm="7">
                  <b-button
                    pill
                    block
                    variant="outline-primary"
                    @click="
                      $router.push(
                        `/patients/${
                          $route.name === 'intakehistory'
                            ? patientInfoGetter.id
                            : patientInfo.id
                        }/message`
                      )
                    "
                  >
                    Reply
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-modal>
      </template>
    </PatientInfoModal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import PatientInfoModal from "@/layouts/modal/PatientInfoModal.vue";
import { format, fromUnixTime } from "date-fns";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    patientInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    PatientInfoModal,
  },
  data() {
    return {
      conversationId: "",
    };
  },
  computed: {
    notesList() {
      return this.$store.state.notes.notesList;
    },
    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
    ...mapGetters({
      patientInfoGetter: "patients/getPatientInfo",
      previousRoute: "globals/previousRoute",
    }),
    ...mapState("message", {
      unreadMessageCounter: "patientUnreadMessageCount",
    }),
  },
  async created() {
    this.$store.dispatch("message/setPatientUnreadMessageCount", 0);
    window.analytics.page();
    this.patientId = this.$route.params.id;
    if (this.$route.name === "intakehistory") {
      await this.$store.dispatch("patients/getPatientInfo", {
        patientId: this.patientId,
      });
    }
    const conversation = await this.$store.dispatch(
      "message/getSingleConversationList",
      {
        patientid: this.patientId,
      }
    );
    if (conversation.length > 0) {
      const { data } = await this.$store.dispatch(
        "message/getUnreadMessageCount",
        {
          conversationid: conversation[0].id,
        }
      );
      this.conversationId = conversation[0].id;
      this.$store.dispatch("message/setPatientUnreadMessageCount", data);
    }
  },
  methods: {
    onCloseModal() {
      this.$emit("update:show-modal", false);
    },
    parseDate(date, stringFormat) {
      return format(fromUnixTime(date), stringFormat);
    },
    pluralize(amount, singular, plural = `${singular}s`) {
      return amount === 1 ? singular : plural;
    },
    onPrescribe() {
      if (this.unreadMessageCounter > 0) {
        this.$bvModal.show("unread-message");
      } else {
        this.$router.push(
          `/prescriptions/order/${
            this.$route.name === "intakehistory"
              ? this.patientInfoGetter.id
              : this.patientInfo.id
          }`
        );
      }
    },
    onMessageRedirect() {
      this.$router.push(
        `/patients/${
          this.$route.name === "intakehistory"
            ? this.patientInfoGetter.id
            : this.patientInfo.id
        }/message`
      );
      this.$store.dispatch("message/markConversationAsRead", {
        conversationid: this.conversationId,
      });
    },
  },
};
</script>

<style scoped>
.health-question-wrapper {
  column-count: 2;
  column-gap: 2em;
}

.health-question {
  display: grid;
  grid-template-rows: 1fr auto;
}

.hq-list > div {
  break-inside: avoid;
}

.health-questions .intake-history-header,
.intake-history-footer {
  background-color: white;
}

.darkmode .intake-history-header,
.darkmode .intake-history-footer {
  background-color: #121212;
}

.unreadcounter-pill {
  background: #ff8d40;
  border-radius: 999px;
  padding: 4px 8px;
  margin-left: 0.25rem;
  font-size: 0.75rem;
  color: #f2f2f2;
}
</style>
