var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.show
      ? _c(
          "div",
          {
            staticClass:
              "modal-patient-overlay tw-flex tw-flex-col tw-top-[80px] tw-left-0 md:tw-flex-row xl:tw-top-0 xl:tw-left-[250px]",
          },
          [
            !_vm.isPatientInfoEmpty
              ? _c("PatientInfo", {
                  staticClass: "tw-flex-none",
                  attrs: {
                    "patient-info": _vm.patientInfo,
                    "show-notes": _vm.showNotesModal,
                    "show-pricing": _vm.showPricingModal,
                  },
                  on: {
                    "update:showNotes": function ($event) {
                      _vm.showNotesModal = $event
                    },
                    "update:show-notes": function ($event) {
                      _vm.showNotesModal = $event
                    },
                    "update:showPricing": function ($event) {
                      _vm.showPricingModal = $event
                    },
                    "update:show-pricing": function ($event) {
                      _vm.showPricingModal = $event
                    },
                  },
                })
              : _c("div", {
                  staticClass:
                    "tw-h-[125px] tw-w-auto tw-flex tw-items-center tw-justify-center tw-bg-contain tw-bg-no-repeat tw-bg-center md:tw-h-screen md:tw-w-[101px] loader-animation",
                }),
            _vm.patientInfo
              ? _c(
                  "div",
                  {
                    staticClass:
                      "tw-flex tw-relative tw-flex-grow modal-patient-content tw-overflow-hidden",
                  },
                  [
                    _vm._t("default", null, {
                      openNotesHandler: _vm.openNotesHandler,
                    }),
                    !_vm.isPatientInfoEmpty
                      ? _c("span", {
                          staticClass: "close-modal",
                          on: {
                            click: function ($event) {
                              _vm.$route.name === "intakehistory"
                                ? _vm.$router.push("/")
                                : _vm.$emit("close")
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _c("modal-set-reminder", { attrs: { patient: _vm.patientInfo } }),
            _c(
              "portal",
              { attrs: { to: "notes" } },
              [
                _c("ModalBodyNotes", {
                  attrs: { "show-notes-modal": _vm.showNotesModal },
                  on: {
                    "update:showNotesModal": function ($event) {
                      _vm.showNotesModal = $event
                    },
                    "update:show-notes-modal": function ($event) {
                      _vm.showNotesModal = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "portal",
              { attrs: { to: "pricing" } },
              [
                _c("ModalPricingList", {
                  attrs: {
                    "show-pricing-modal": _vm.showPricingModal,
                    patientId: _vm.patientInfo.id,
                    pricingversion: _vm.patientInfo.pricingversion || "v1",
                  },
                  on: {
                    "update:showPricingModal": function ($event) {
                      _vm.showPricingModal = $event
                    },
                    "update:show-pricing-modal": function ($event) {
                      _vm.showPricingModal = $event
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }