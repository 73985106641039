<template>
  <transition name="fade">
    <div
      class="
        modal-patient-overlay
        tw-flex tw-flex-col tw-top-[80px] tw-left-0
        md:tw-flex-row
        xl:tw-top-0 xl:tw-left-[250px]
      "
      v-if="show"
    >
      <PatientInfo
        class="tw-flex-none"
        :patient-info="patientInfo"
        :show-notes.sync="showNotesModal"
        :show-pricing.sync="showPricingModal"
        v-if="!isPatientInfoEmpty"
      />

      <div
        class="
          tw-h-[125px]
          tw-w-auto
          tw-flex
          tw-items-center
          tw-justify-center
          tw-bg-contain
          tw-bg-no-repeat
          tw-bg-center
          md:tw-h-screen md:tw-w-[101px]
          loader-animation
        "
        v-else
      ></div>
      <div
        class="
          tw-flex tw-relative tw-flex-grow
          modal-patient-content
          tw-overflow-hidden
        "
        v-if="patientInfo"
      >
        <slot :openNotesHandler="openNotesHandler"></slot>
        <span
          class="close-modal"
          @click="
            $route.name === 'intakehistory' ? $router.push('/') : $emit('close')
          "
          v-if="!isPatientInfoEmpty"
        ></span>
      </div>

      <modal-set-reminder :patient="patientInfo"></modal-set-reminder>

      <portal to="notes">
        <ModalBodyNotes :show-notes-modal.sync="showNotesModal" />
      </portal>

      <portal to="pricing">
        <ModalPricingList
          :show-pricing-modal.sync="showPricingModal"
          :patientId="patientInfo.id"
          :pricingversion="patientInfo.pricingversion || 'v1'"
        />
      </portal>
    </div>
  </transition>
</template>

<script>
import PatientInfo from "@/components/modal/ModalPatientInfo";
import ModalBodyNotes from "@/components/patients/ModalBodyNotes.vue";
import ModalSetReminder from "@/components/reminder/ModalSetReminder.vue";
import ModalPricingList from "@/components/patients/ModalPricingList.vue";

import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  name: "PatientInfoModal",
  components: {
    PatientInfo,
    ModalBodyNotes,
    ModalSetReminder,
    ModalPricingList,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showNotesModal: false,
      showPricingModal: false,
    };
  },
  computed: {
    ...mapGetters({
      patientInfo: "patients/getPatientInfo",
    }),
    isPatientInfoEmpty() {
      return _.isEmpty(this.patientInfo);
    },
  },
  methods: {
    openNotesHandler() {
      this.showNotesModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-patient-overlay {
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: #ffecca;
  z-index: 800;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    z-index: 890;
  }
}

.loader-animation {
  background-color: #ffecca;
  background-image: url("~@/assets/images/loader.gif");
}

.darkmode .loader-animation {
  background-image: url("~@/assets/images/dark-loader.gif");
}

.modal-patient-content {
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}

.darkmode .loader-animation,
.darkmode .modal-patient-overlay,
.darkmode .modal-patient-content {
  background-color: #121212;
}

.close-modal {
  cursor: pointer;
  position: absolute;
  top: 23px;
  right: 15px;
  z-index: 2;
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjIiIHZpZXdCb3g9IjAgMCAyMiAyMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iMTEiIGZpbGw9IiNGRjhENDAiLz4KPHBhdGggZD0iTTcuNzY0NDcgNy43NjQ3MUwxNC4yMzUxIDE0LjIzNTMiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS44IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTE0LjIzNDYgNy43NjQ1OUw3Ljc2NDAzIDE0LjIzNTIiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS44IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==");

  @include media-breakpoint-up(md) {
    top: 20px;
    right: 30px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(100px);
}
</style>
